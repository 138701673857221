<template>
  <div>
    <div class="container-fluid pt-7 pb-5">
      <el-collapse class="expand-main mb-3 shadow">
        <el-collapse-item class="expand-item">
          <template #title>
            <div class="row pl-3">
              <div class="col-12 d-flex align-items-center">
                <i class="fas fa-search"></i>
                <h3 class="mb-0 ml-2">{{ $t.translate("LBL_SEARCH") }}</h3>
              </div>
            </div>
          </template>
          <card shadow type="secondary" class="">
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.ClientNo"
                    :label="$t.translate('FRM_CLIENT_NO')"
                    input-classes="form-control-alternative"
                  />
                </div>

                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.Name"
                    :label="$t.translate('FRM_NAME')"
                    input-classes="form-control-alternative"
                  />
                </div>

                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.Email"
                    :label="$t.translate('FRM_EMAIL')"
                    input-classes="form-control-alternative"
                  />
                </div>

                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.BusinessStaff"
                    :label="$t.translate('FRM_REP_BUSIN_STAFF')"
                    input-classes="form-control-alternative"
                  />
                </div>

                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.OrgName"
                    :label="$t.translate('FRM_ORGANIZATION_NAME')"
                    input-classes="form-control-alternative"
                  />
                </div>

                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.BranchName"
                    :label="$t.translate('FRM_BRANCH')"
                    input-classes="form-control-alternative"
                  />
                </div>
              </div>
              <div class="row mt-1 mb-2">
                <div class="col-lg-6">
                  <el-button type="primary" @click="filterAction">
                    {{ $t.translate("BTN_SEARCH") }}
                  </el-button>
                  <el-button type="primary" class="ml-3" @click="resetParams">
                    {{ $t.translate("BTN_RESET") }}
                  </el-button>
                </div>
              </div>
            </div>
          </card>
        </el-collapse-item>
      </el-collapse>

      <div class="d-flex justify-content-end">
        <el-button
          v-if="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT', 'SCHEDULING'])"
          type="default"
          class="mb-3"
          @click="() => exportUser($refs)"
        >
          {{ $t.translate("BTN_EXPORT") }}
        </el-button>

        <el-button
          v-if="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT'])"
          type="primary"
          class="mb-3"
          @click="addUser"
        >
          {{ $t.translate("BTN_ADD") }}
        </el-button>
      </div>

      <div class="shadow bg-white p-around">
        <Table
          url="/user/list"
          :query="{ ...Filter }"
          ref="filttable"
          :default-sort="{ prop: Filter.SortBy, order: Filter.SortOrder }"
          @sort-change="sortChange"
          :height="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT']) ? '61vh' : '68vh'"
        >
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="ClientNo"
            :label="$t.translate('FRM_CLIENT_NO')"
            min-width="140px"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ scope.row.ClientNo }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="AssignedTeam"
            :label="$t.translate('FRM_HKQAA_BUSINESS_TEAM')"
            min-width="150px"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ scope.row.AssignedTeam || "--" }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="AssignedStaff"
            :label="$t.translate('FRM_REP_BUSIN_STAFF')"
            min-width="150px"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ scope.row.AssignedStaff || "--" }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="Name"
            :label="$t.translate('FRM_USER_NAME')"
            min-width="150px"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ $h.getFullName(scope.row) }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="Email"
            :label="$t.translate('FRM_USER_EMAIL')"
            width="190px"
          ></el-table-column>

          <el-table-column
            :label="$t.translate('FRM_ORGANIZATION_NAME')"
            min-width="200px"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="OrgName"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ $h.formatName(scope.row, "OrgName") }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="BranchName"
            :label="$t.translate('FRM_BRANCH')"
            min-width="182"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ $h.formatName(scope.row, "BranchName") }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t.translate('LBL_REP_NAME')"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="RepName"
            min-width="190"
          >
            <template #default="scope">
              {{ scope.row.RepName || "---" }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="RepEmail"
            :label="$t.translate('LBL_REP_EMAIL')"
            min-width="190"
          >
            <template #default="scope">
              {{ scope.row.RepEmail || "---" }}
            </template>
          </el-table-column>

          <el-table-column :label="$t.translate('LBL_STATUS')" min-width="100">
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.filttable.currentSelection(arr, 'UserStatusId')
                "
                :hasSelection="true"
                :fieldName="$t.translate('LBL_STATUS')"
                url="envconf/UserStatusOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              <el-tag
                :type="scope.row.UserStatusCode === 'ACTIVE' ? 'primary' : 'info'"
                disable-transitions
                >{{ $t.translate(`LBL_USER_${scope.row.UserStatusCode}`) }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="LastCreated"
            :label="$t.translate('FRM_LAST_CREATED')"
            width="140px"
          >
            <template #default="scope">
              {{ $h.formatDateTime(scope.row.LastCreated) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t.translate('LBL_ACTION')"
            width="70"
            fixed="right"
            class-name="pl-1 action-col"
          >
            <template #default="scope">
              <el-dropdown
                trigger="click"
                :hide-on-click="true"
                @command="(comd) => handleOptions(scope.row, comd, $refs)"
              >
                <el-button type="primary" size="mini">
                  <i class="el-icon-arrow-down"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :disabled="!$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT'])"
                      command="send"
                      >{{
                        $t.translate("MSG_SEND_EMAIL_PASSWORD_CREATION_LINK")
                      }}</el-dropdown-item
                    >

                    <el-dropdown-item
                      :disabled="
                        !$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT']) ||
                        scope.row.UserStatusCode == 'ACTIVE'
                      "
                      command="activate"
                      >{{ $t.translate("BTN_ACTIVATE") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      :disabled="
                        !$h.hasRoles([
                          'ADMIN',
                          'SUB_ADMIN',
                          'IT',
                          'BUSINESS',
                          'FINANCE',
                          'SCHEDULING',
                        ])
                      "
                      command="edit"
                      >{{ $t.translate("BTN_EDIT") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      :disabled="
                        !$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'BUSINESS', 'SCHEDULING'])
                      "
                      command="manage"
                      >{{ $t.translate("BTN_MANAGE_CHILD_ACCOUNT") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      :disabled="!$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT'])"
                      command="remove"
                      >{{ $t.translate("BTN_DELETE") }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </Table>
      </div>
    </div>
  </div>

  <ExportModal :types="['EXCEL']" ref="export" />
</template>
<script>
import ExportModal from "@/components/ExportModal";
import Table from "@/components/Table.vue";
import TableFilter from "@/components/TableFilter.vue";
import { reactive, onMounted, inject, ref } from "vue";
import { get, post, isValidResponse } from "@/utils/axios";
import { translation } from "@/utils/translation";
import { router } from "@/utils/router";
import { helpers } from "@/utils/helpers";
import { ElMessageBox } from "element-plus";

export default {
  components: { Table, TableFilter, ExportModal },
  setup() {
    let fieldsToSkip = ["SortBy", "SortOrder", "RoleCode"];
    let filttable = ref("filttable");
    let Filter = reactive({
      Name: "",
      Email: "",
      OrgName: "",
      BranchName: "",
      ClientNo: "",
      BusinessStaff: "",

      RoleCode: "CLIENT",
      SortBy: "LastCreated",
      SortOrder: "descending",
    });

    const addUser = () => {
      router.changeRoute("/user/add");
    };

    const handleDelete = async (index, data) => {
      try {
        let confirmed = await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/user/remove", { UserKey: data.UserKey });
        if (!isValidResponse(res)) return;

        filterAction();
      } catch (err) {
        //err
      }
    };

    const handSend = async (data) => {
      try {
        let confirmed = await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_ACTION"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_CONFIRM"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/user/send", { UserKey: data.UserKey });
        if (!isValidResponse(res)) return;

        helpers.showMessage({
          Code: "MSG_SUCCESS",
          Message: "MSG_REQUEST_SUCCESS",
        });
      } catch (err) {
        //err
      }
    };

    const handleActivate = async (index, data) => {
      try {
        let confirmed = await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_ACTION"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_CONFIRM"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/user/activate", { UserKey: data.UserKey });
        if (!isValidResponse(res)) return;

        filterAction();
      } catch (err) {
        //err
      }
    };

    let filterAction = async () => {
      await filttable.value.handleCurrentChange(1, Filter);
    };

    const sortChange = ({ prop, order }) => {
      Filter.SortBy = prop;
      Filter.SortOrder = order;
      filterAction();
    };

    const filterNow = (page, filter = null) => {
      filttable.value.handleCurrentChange(page, filter);
    };

    const resetParams = () => {
      for (let key in Filter) {
        if (fieldsToSkip.indexOf(key) > -1) continue;
        Filter[key] = "";
      }
      filterNow(1, Filter);
    };

    const exportUser = ($refs) => {
      let FilterTable = { ...Filter, Filter: $refs?.filttable?.Filter?.Filter || "" };

      $refs.export.exportContent({
        Mode: "GENERATE_CLIENT",
        Filter: FilterTable,
        ExcelOnly: true,
      });
    };

    const handleOptions = async (row, cmd, $refs) => {
      if (cmd == "remove") {
        await handleDelete(0, row);
      } else if (cmd == "manage") {
        router.changeRoute(`/child`, { key: row.UserKey });
      } else if (cmd == "edit") {
        router.changeRoute(`/user/edit/${row.UserKey}`);
      } else if (cmd == "activate") {
        await handleActivate(0, row);
      } else if (cmd == "send") {
        await handSend(row);
      }
    };

    return {
      exportUser,
      filterAction,
      filterNow,
      sortChange,
      Filter,
      filttable,
      handleOptions,
      resetParams,
      addUser,
    };
  },
};
</script>
<style></style>
